import React from 'react';
import './Footer.css';
import HomeSVG from './HomeSVG.svg'
import DashboardSVG from './DashboardSVG.svg'
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer">
    <Link to="/">
      <div className="container">
        <img src={HomeSVG} width={30} height={30} alt="Home Icon" />
        <p>Home</p>
      </div>
    </Link>
    <Link to="/dashboard">
      <div className="container">
        <img src={DashboardSVG} width={30} height={30} alt="Dashboard Icon" />
        <p>Dashboard</p>
      </div>
    </Link>
  </footer>
  );
};

export default Footer;
