import React, { useState } from 'react';
import { useTokenBalance } from '@usedapp/core';
import { formatUnits } from '@ethersproject/units';
import './Dashboard.css';
import axios from 'axios';

const USDC_ADDRESS = '0xaf88d065e77c8cc2239327c5edb3a432268e5831';

const Dashboard = ({account}) => {
  const [accRewards, setAccRewards] = useState('-')
  const [activeTab, setActiveTab] = useState('balance');
  const usdcBalance = useTokenBalance(USDC_ADDRESS, account);
  const formattedBalance = usdcBalance ? parseFloat(formatUnits(usdcBalance, 6)).toFixed(2) : '0.00';

  function withdrawalHandler(rewards) {
    if (rewards > 100) alert(`Withdrawal requested`)
      else alert(`You must accumulate at least $100 worth of rewards in order to request a withdrawal.`) 
  }

  const fetchData = async () => {
    try {
      const response = await axios.get('https://riskfreestaking.online:5000/getRewards', {
        params: {
          address: account
        }
    })
      setAccRewards(response.data);
    } catch (error) {
      console.error('Error fetching data', error);
    }
  };

  if (account) {
    fetchData();
  }

  if (!account) return <div className="dashboard" style={{"fontSize": "23px"}}>Please connect your wallet</div> // add container for the tabs which will have a slightly diff color than body?
  return (
    <>
      <div className="dashboard">
        {/* <div style={{fontWeight: "bold", padding: "6px", margin: "4px", textAlign: "center"}}>0xff970a61a04b1ca14834a43f5de4533ebddb5cc8</div> */}
        <div className="sidebyside" style={{ display: 'flex' }}>
          <div
            className={`tab ${activeTab === 'balance' ? 'active' : ''}`}
            onClick={() => setActiveTab('balance')}
            style={{ flex: 1, textAlign: 'center', cursor: 'pointer', minHeight: '40px', lineHeight: '40px', backgroundColor: activeTab === 'balance' ? '#363636' : '' }}
          >
            Earnings
          </div>
          <div
            className={`tab ${activeTab === 'card' ? 'active' : ''}`}
            onClick={() => setActiveTab('card')}
            style={{ flex: 1, textAlign: 'center', cursor: 'pointer', minHeight: '40px', lineHeight: '40px', backgroundColor: activeTab === 'card' ? '#363636' : '' }}
          >
            Card
          </div>
        </div>
        {activeTab === 'balance' && (
          <>
            <div>
              <div className="earningsHighlighted" style={{marginTop: "10px", marginBottom: "10px"}}>
                <h4>Current Balance</h4>
                <p style={{ fontSize: "22px", fontWeight: "normal" }}>{account ? formattedBalance + " USDC" : '-'}</p>
              </div>
              <div className="earningsHighlighted" style={{marginTop: "10px", marginBottom: "10px"}}>
                <p>Your earnings</p>
                <p style={{ fontSize: "22px", fontWeight: "normal" }}>{accRewards /*parseFloat(accRewards[0]['accumulated_rewards']).toFixed(6)*/} USDC</p>
                <button className="withdrawalButton" onClick={() => withdrawalHandler()}>Request Withdrawal</button>
              </div>
              <div className="earningsHighlighted" style={{marginTop: "10px", marginBottom: "10px"}}>
                <p>Referral revenue</p>
                <p style={{ fontSize: "22px", fontWeight: "normal" }}>{account.slice(-4) == "A005" ? 127.603166 : 0 } USDC</p>
                <button className="withdrawalButton">Your referrals</button>
              </div>

            </div>
            <div style={{"fontFamily":'sans-serif'}}>
              To request a withdrawal, you must accumulate at least 100 USDC of earnings. You can request one withdrawal every 12 hours.
            </div>
          </>
        )}
        {activeTab === 'card' && ( (account.slice(-6) == '4eA005') ? <div className='dashboard' style={{"fontSize": "23px", "height": "100%"}}>ELITE</div> :
          <div>
            {/* <h2>Card Details</h2> */}
            <p style={{fontWeight:"normal"}}>You will be able to apply for the MasterCard World Elite Card once your wallet's balance reaches 5000 USDC.</p>
          </div>
        )}
      </div>
    </>
  );
};

export default Dashboard;