import React from 'react';
import { useEthers } from '@usedapp/core';
import './Navbar.css'
import circlelogo from './circle--logo.png'

function Navbar({ setAcc }) { // check is registered?
  const { activateBrowserWallet, account, chainId, switchNetwork } = useEthers()
  if (account) setAcc(account)
  return (
    <nav className="navbar">
      <a href='https://www.circle.com'><img src={circlelogo} width={50} height={45} style={{marginTop: "3px"}}></img></a>
      {account ?
        <div className='addressField' style={{ right: "5%", position: "relative", fontSize:'14px' }}>{account.slice(0,4) + '...' + account.slice(-4)}</div>
      : <button className='connectButton' onClick={async () => {
        switchNetwork(42161)
        activateBrowserWallet()
        setAcc(account)
      }} style={{ top: "20px", right: "5%", position: "relative" }}>
      Connect Wallet
    </button>}
    </nav>
  );
}

export default Navbar;
