import { BrowserRouter as Router, Route, Switch, Link, Routes, Navigate } from 'react-router-dom';
import Scripts from './Scripts';
import Main from './Main';
import Footer from './Footer';
import Navbar from './Navbar';
import Dashboard from './Dashboard';
import { DAppProvider, Config, Mainnet, Arbitrum } from '@usedapp/core'
import './App.css';
import { useState } from 'react';

function App() {
  const [acc, setAcc] = useState(undefined)
  const config = {
    readOnlyChainId: Arbitrum.chainId,
    readOnlyUrls: {
      [Arbitrum.chainId]: 'https://arb1.arbitrum.io/rpc'
    },
    networks: [Arbitrum]
  }
  return (
    <DAppProvider config={config}>
        <Navbar account={acc} setAcc={acc => setAcc(acc)}></Navbar>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/dashboard" element={<Dashboard account={acc}/>} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        <Footer />
    </DAppProvider>
  );
}

export default App;
